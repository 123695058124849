<template>
  <div class="admin-wrap">
    <AdminMenu active-item="clients" />
    <div class="admin-main">
      <AdminHeader title="客戶管理" backlink="javascript:history.back()" />
      <div class="client-detail">
        <div class="client-detail__basic-info">
          <div class="client-detail__basic-info-inner">
            <div class="client-detail__col">
              <h3>基本資料</h3>
              <div class="client-detail__form">
                <div class="client-detail__row">
                  <label for="name">姓名</label>
                  <input type="text" id="name" v-model="patient.fullName">
                </div>
                <div class="client-detail__row">
                  <label for="id-number">身分證</label>
                  <input type="text" id="id-number" v-model="patient.idNumber">
                </div>
                <div class="client-detail__row">
                  <label for="phone">電話</label>
                  <input type="tel" id="phone" v-model="patient.phone">
                </div>
                <div class="client-detail__row">
                  <label for="birthday">生日</label>
                  <input type="date" id="birthday" v-model="patient.birthDate">
                </div>
                <div class="client-detail__row">
                  <label for="email">電郵</label>
                  <input type="email" id="email" v-model="patient.email">
                </div>
                <div class="client-detail__row">
                  <label for="address">地址</label>
                  <input type="text" id="address" v-model="patient.address">
                </div>
                <div class="client-detail__row">
                  <label for="type">類別</label>
                  <div class="client-detail__select-wrap">
                    <select name="type" id="type" v-model="patient.patientTypeId">
                      <option v-for="patientType in patientTypes" :key="patientType" :value="patientType.id">{{patientType.name}}</option>
                    </select>
                  </div>
                </div>
                <div class="client-detail__row">
                  <label for="picture">照片</label>
                  <div class="client-detail__avatar">
                    <div class="client-detail__picture-wrap">
                    <img :src="photo" alt="">
                    </div>
                  <input type="file" @change="handleImage" accept="image/*">
                  </div>
                </div>
              </div>
            </div>
            <div class="client-detail__col">
              <!-- <h3>消費統計</h3>
              <div class="client-detail__spend-detail">
                <p>累計消費：<strong>525,000</strong></p>
                <p>當月消費：<strong>23,000</strong></p>
              </div> -->
              <div class="client-detail__memo">
                <h4>備註</h4>
                <div class="client-detail__memo-detail">
                  <span>1</span>
                  <input type="text" v-model="patient.remark1">
                </div>
                <div class="client-detail__memo-detail">
                  <span>2</span>
                  <input type="text" v-model="patient.remark2">
                </div>
                <div class="client-detail__memo-detail">
                  <span>3</span>
                  <input type="text" v-model="patient.remark3">
                </div>
              </div>
            </div>
          </div>
          <button class="admin-btn-lg" @click="onSave">儲存</button>
        </div>
        <div class="client-detail__log" v-if="patient.id > 0">
          <h3>預約記錄</h3>
          <table class="client-detail__table">
            <thead>
              <tr>
                <th>日期</th>
                <th>時段</th>
                <th>診療項目</th>
                <th>醫師</th>
                <th>預約人員</th>
                <th>服務人員</th>
                <th>狀態</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="record in history" :key="record">
                <td>{{record.date}}</td>
                <td>{{record.time}}</td>
                <td>{{record.treatmentName}}</td>
                <td>{{record.doctor}}</td>
                <td>{{record.reservedStaffName}}</td>
                <td>{{record.serviceStaffName}}</td>
                <td>{{record.status}}</td>
              </tr>
            </tbody>
          </table>
          <div class="admin-pagination">
            <button class="admin-pagination__btn-prev" :class="{ 'is-disable' : !canPrevPage }" @click="onPrevPage">上一頁</button>
            <span class="admin-pagination__current">{{historyPage}}</span>
            <span class="admin-pagination__page">/ {{historyTotalPages}}頁</span>
            <button class="admin-pagination__btn-next" :class="{ 'is-disable' : !canNextPage }" @click="onNextPage">下一頁</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { apiGetPatient, photoUrl, apiCreatePatient, apiUpdatePatient, apiGetPatientTypes, apiGetReservationHistory } from '@/api/v1/admin'
import AdminMenu from '../../components/AdminMenu'
import AdminHeader from '../../components/AdminHeader'

export default {
  name: 'ClientDetail',
  props: ['clientId'],
  components: { AdminMenu, AdminHeader },
  created () {
    this.fetchData()
  },
  watch: {
    historyPage: {
      handler: function () {
        this.loadHistory()
      },
      deep: true
    }
  },
  data () {
    return {
      patient: {
        id: 0,
        fullName: '',
        idNumber: '',
        phone: '',
        birthDate: '',
        email: '',
        address: '',
        patientTypeId: 1,
        remark1: '',
        remark2: '',
        remark3: ''
      },
      patientTypes: [],
      photo: '',
      isUploadPhoto: false,
      history: [],
      historyPage: 1,
      historySize: 10,
      historyTotal: 0
    }
  },
  computed: {
    historyTotalPages () {
      return Math.ceil(this.historyTotal / this.historySize)
    },
    canNextPage () {
      return this.historyPage < this.historyTotalPages
    },
    canPrevPage () {
      return this.historyPage > 1
    }
  },
  methods: {
    fetchData () {
      if (this.clientId > 0) {
        apiGetPatient(this.clientId).then(data => {
          console.log(data)
          if (data.error) {
            console.log(data.error.message)
          } else {
            this.patient = data
            this.loadPhoto()
          }
        })
        this.loadHistory()
      }
      apiGetPatientTypes().then(data => {
        console.log(data)
        if (data.error) {
          console.log(data.error.message)
        } else {
          this.patientTypes = data
        }
      })
    },
    loadHistory () {
      apiGetReservationHistory({
        patientId: this.clientId,
        data: {
          page: this.historyPage,
          size: this.historySize,
          order: 'desc'
        }
      }).then(data => {
        console.log(data)
        if (data.error) {
          console.log(data.error.message)
        } else {
          this.history = data.content
          this.historyTotal = data.totalItems
        }
      })
    },
    loadPhoto () {
      if (this.patient.photo) {
        this.photo = photoUrl('patients', this.clientId)
      }
    },
    handleImage (e) {
      const selectedImage = e.target.files[0]
      console.log('handleImage')
      this.createBase64Image(selectedImage)
    },
    createBase64Image (fileObject) {
      const reader = new FileReader()
      reader.onload = (e) => {
        this.photo = e.target.result
        this.isUploadPhoto = true
      }
      reader.readAsDataURL(fileObject)
    },
    onSave () {
      // TODO: verify input
      const params = {
        patientId: this.clientId,
        data: JSON.parse(JSON.stringify(this.patient))
      }
      if (this.isUploadPhoto) {
        params.data.photoContent = this.photo
      }
      const api = (this.clientId > 0 ? apiUpdatePatient : apiCreatePatient)

      api(params).then(data => {
        console.log(data)
        if (data.error) {
          this.$swal('儲存失敗', data.message, 'warning')
        } else {
          this.$swal('儲存完成', '', 'success').then(() => history.back())
        }
      }).catch(err => {
        this.$swal('儲存失敗', err.data.error, 'warning')
      })
    },
    onNextPage () {
      if (this.historyPage < this.historyTotalPages) {
        this.historyPage++
      }
    },
    onPrevPage () {
      if (this.historyPage > 1) {
        this.historyPage--
      }
    }
  }
}
</script>

<style>

</style>
